import { computed, defineComponent, reactive, nextTick, ref } from 'vue';
// import {useHead} from '@vueuse/head';
import TLayout from '@/components/Layout/Layout.vue';
import UpdateTime from '@/components/UpdateTime/index.vue';
import useStoreRequest from '@/base/hooks/useStoreRequest';
import { getSubjectMergeList } from '@/base/api/subject';
import { SubjectMutationEnum } from '@/store/subject';
import { IS_NODE } from '@/base/utils';
const subjectMergeListConf = [{
  key: 'platform',
  name: '跨境平台',
  list: []
}, {
  key: 'collection',
  name: '收款工具',
  list: []
}, {
  key: 'extension',
  name: '营销推广',
  list: []
}, {
  key: 'logistics',
  name: '跨境物流',
  list: []
}, {
  key: 'service',
  name: '跨境服务',
  list: []
}];
export default defineComponent({
  name: 'Subject',
  components: {
    TLayout,
    UpdateTime
  },
  setup() {
    // useHead({...DEFAULT_SEO_CONFIG, title: (pageTitle || DEFAULT_SEO_CONFIG.title)});
    // start - 主体聚合数据
    const {
      state: subjectMergeListRes
    } = useStoreRequest({
      space: 'subject',
      mutationName: SubjectMutationEnum.SET_SUBJECT_MERGET_LIST,
      stateKey: 'subjectMergeList'
    }, getSubjectMergeList);
    const subjectMergeListData = computed(() => {
      return subjectMergeListRes.value.data || {};
    });
    let objNull = ref(true);
    const subjectContentItems = computed(() => {
      const retArr = subjectMergeListConf.map(item => {
        var _subjectMergeListData;
        if ((_subjectMergeListData = subjectMergeListData.value) !== null && _subjectMergeListData !== void 0 && _subjectMergeListData[item.key]) {
          var _subjectMergeListData2, _item$list;
          item.list = (_subjectMergeListData2 = subjectMergeListData.value) === null || _subjectMergeListData2 === void 0 ? void 0 : _subjectMergeListData2[item.key];
          if (((_item$list = item.list) === null || _item$list === void 0 ? void 0 : _item$list.length) > 0) {
            objNull.value = false;
          }
          return item;
        }
      });
      if (objNull.value) {
        return [];
      }
      return retArr;
    });
    // end - 主体聚合数据
    // nav start
    let activeIndex = reactive({
      0: true
    });
    const handleClickNav = (item, index) => {
      // 清空reactive中的默认对象
      Object.keys(activeIndex).map(key => {
        delete activeIndex[key];
      });
      // 点击赋值
      activeIndex[index] = true;
      // nav点击滑动
      const el = document.querySelector(`#${item.key}`);
      if (!IS_NODE && el) {
        nextTick(() => {
          // 72：header高度； 16：每个tab的margin-bottom
          window.scrollTo({
            top: index === 0 ? -208 : -72 - 16 + ((el === null || el === void 0 ? void 0 : el.offsetTop) || 0),
            left: 0,
            behavior: 'smooth'
          });
        });
      }
    };
    // nav end
    return {
      subjectContentItems,
      handleClickNav,
      activeIndex,
      objNull
    };
  }
});